import RunGsap from '../../helpers/RunGsap';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import IconPlus from '../../components/Icons/IconPlus';
import Modal from '../../components/Modal/Modal';
import { useState } from 'react';

function Settings() {
  const [ModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <div className="page page-Settings">
      <RunGsap />
      <Module>
        <ModuleText>
          <div className="module-Title">
            <h1>Everything in order?</h1>
          </div>
          <div className="settingsList">
            <div className="settingsList-Item animateToken">
              <div className="settingsList-Item_Top">
                <div className="settingsList-Item_Title typeNormal medium">
                  Linked Wallet
                </div>
                <div onClick={handleOpenModal} className="icon">
                  <IconPlus />
                </div>
              </div>
              <div className="settingsList-Item_Bottom">
                <div className="settingsList-Item_Item typeNormal normal">
                  Ex03...H3922
                </div>
              </div>
            </div>
            <div className="settingsList-Item animateToken">
              <div className="settingsList-Item_Top">
                <div className="settingsList-Item_Title typeNormal medium">
                  Linked mmERCH
                </div>
                <div onClick={handleOpenModal} className="icon">
                  <IconPlus />
                </div>
              </div>
              <div className="settingsList-Item_Bottom">
                <div className="settingsList-Item_Item typeNormal normal">
                  Ex55...2A4G
                </div>
                <div className="settingsList-Item_Item typeNormal normal">
                  Ex84...43AX
                </div>
              </div>
            </div>
            <div className="settingsList-Item animateToken">
              <div className="settingsList-Item_Top">
                <div className="settingsList-Item_Title typeNormal medium">
                  Linked email
                </div>
                <div onClick={handleOpenModal} className="icon">
                  <IconPlus />
                </div>
              </div>
              <div className="settingsList-Item_Bottom">
                <div className="settingsList-Item_Item typeNormal normal">
                  test...@gmail.com
                </div>
              </div>
            </div>
            <div className="settingsList-Item animateToken">
              <div className="settingsList-Item_Top">
                <div className="settingsList-Item_Title typeNormal medium">
                  Not you?
                </div>
                <div onClick={handleOpenModal} className="icon">
                  <IconPlus />
                </div>
              </div>
              <div className="settingsList-Item_Bottom">
                <div className="settingsList-Item_Item typeNormal normal">
                  Or want to transfer ownership? De-register here.
                </div>
              </div>
            </div>
          </div>
        </ModuleText>
      </Module>
      <Modal
        title="Modal Title"
        btnTitle="roger that"
        isOpen={ModalOpen}
        onClose={handleCloseModal}
        hasCloseBtn
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </Modal>
      <div className="page-Bottom" />
    </div>
  );
}

export default Settings;
