const IconCloset = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        d="m8 14.6-1.333.733a1.306 1.306 0 0 1-1 .134 1.225 1.225 0 0 1-.8-.6L2.2 10.2a1.306 1.306 0 0 1-.133-1c.089-.356.289-.622.6-.8L10.334 4h2.333c.2 0 .361.061.483.183a.653.653 0 0 1 .184.484v.666c0 .734.26 1.361.783 1.884.522.522 1.15.783 1.883.783.734 0 1.361-.261 1.884-.783.522-.523.783-1.15.783-1.884v-.666c0-.2.061-.361.183-.484A.654.654 0 0 1 19.334 4h2.333l7.667 4.4c.31.178.51.444.6.8.088.356.044.689-.134 1l-2.666 4.667a1.163 1.163 0 0 1-.784.583 1.43 1.43 0 0 1-1.016-.15L24 14.633v12.034c0 .377-.128.694-.383.95a1.29 1.29 0 0 1-.95.383H9.334a1.29 1.29 0 0 1-.95-.383 1.29 1.29 0 0 1-.384-.95V14.6Zm2.667-4.467v15.2h10.667v-15.2l4.133 2.267 1.4-2.333-5.733-3.334A5.555 5.555 0 0 1 19.25 9.55c-.922.744-2.005 1.117-3.25 1.117-1.244 0-2.328-.373-3.25-1.117a5.555 5.555 0 0 1-1.883-2.817l-5.733 3.334 1.4 2.333 4.133-2.267Z"
        fill="CurrentColor"
      />
    </svg>
  );
};
export default IconCloset;
