import './Module.sass';

interface ModuleProps {
  children: React.ReactNode;
  hasEvenCorners?: boolean;
  noTopPadding?: boolean;
  noBottomPadding?: boolean;
  className?: string;
}
export default function Module({
  children,
  hasEvenCorners,
  noTopPadding,
  noBottomPadding,
  className,
}: ModuleProps) {
  return (
    <div
      className={`${className} module ${
        hasEvenCorners ? 'hasEvenCorners' : ''
      } ${noTopPadding ? 'noTopPadding' : ''} ${
        noBottomPadding ? 'noBottomPadding' : ''
      } section`}
    >
      {children}
    </div>
  );
}
