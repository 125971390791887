import { useState, useEffect } from 'react';
import axios from 'axios';
import RunGsap from '../../helpers/RunGsap';
import Loader from '../../components/Loader/Loader';
import Subtitle from '../../components/Subtitle/Subtitle';
import EventCard from '../../components/EventCard/EventCard';
import Modal from '../../components/Modal/Modal';
import BulletPoint from '../../components/Module/ModuleText/BulletPoint/BulletPoint';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import Button from '../../components/Button/Button';
import IconArrow from '../../components/Icons/IconArrow';
import ButtonContainer from '../../components/ButtonContainer/ButtonContainer';
import ModuleImage from '../../components/Module/ModuleImage/ModuleImage';
import { FETCH_STATUS } from '../../helpers/fetchStatus';

function Home(props: {
  isLoading: boolean;
  nftData: any;
  experienceTokens: any[];
}) {
  let nftData = props.nftData;
  let experienceTokens = props.experienceTokens;
  const landingImage = nftData.renderImage;
  const [ModalOpen, setModalOpen] = useState(false);
  interface Event {
    image: string;
    path: string;
  }

  const [eventList, setEventList] = useState<Event[]>([]);
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);

  const fetchEvents = async () => {
    try {
      setStatus(FETCH_STATUS.LOADING);
      const response = await axios.get(
        'https://router.mmerch.xyz/test/check-in/events/all'
      );
      let events = response.data;
      if (!Array.isArray(events)) {
        events = Object.values(events);
      }
      if (events) {
        setEventList(events[0]);
        setStatus(FETCH_STATUS.SUCCESS);
      }
    } catch (error) {
      setStatus(FETCH_STATUS.ERROR);
      console.error('Error fetching events data:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <div className="page page-Home">
      {/* <RunGsap /> */}
      <div className="landing section">
        <div
          className={`landing-Image loadable ${
            props.isLoading ? 'isLoading' : 'isLoaded'
          }`}
        >
          {<img src={landingImage} alt="" loading="lazy" />}
        </div>
        <div className="landing-Text">
          {props.isLoading ? <h1>Loading...</h1> : <h1>Oh, hi there.</h1>}
        </div>
      </div>
      <div className="sectionGroup">
        <Subtitle
          text="current & upcoming experiences"
          id="events"
          help
          onClick={handleOpenModal}
        />
        {status === FETCH_STATUS.SUCCESS ? (
          eventList.map((event, index) => (
            <EventCard
              key={index}
              hasButton
              background={event.image}
              path={event.path}
            />
          ))
        ) : (
          <Loader />
        )}
      </div>
      {experienceTokens.length > 0 && (
        <>
          <div className="sectionGroup">
            <Subtitle text="Your latest XP" className="forceOpacity" />
            <Module
              hasEvenCorners
              noBottomPadding
              className="card forceOpacity"
            >
              <ModuleText>
                <div className="module-Title">
                  <h1 className="truncate">
                    {experienceTokens[experienceTokens.length - 1].name}
                  </h1>
                  <p className="date truncate">
                    {
                      experienceTokens[experienceTokens.length - 1]
                        .attributes[0].value
                    }
                  </p>
                </div>
              </ModuleText>
              <ButtonContainer floatRight>
                <Button className="green forceOpacity" forward path="/wallet">
                  View all
                  <div className="icon">
                    <IconArrow direction="forward" />
                  </div>
                </Button>
              </ButtonContainer>
              <ModuleImage>
                <img
                  src={experienceTokens[experienceTokens.length - 1].image}
                  alt="nft"
                />
              </ModuleImage>
            </Module>
          </div>
        </>
      )}

      {/* 
        Doing this allows us to precache the images on the home page
        before we get to the traits page...  */}
      <img src={props.nftData?.nftImage} alt="" style={{ display: 'none' }} />
      <Modal
        title="Earn NFTs IRL."
        isOpen={ModalOpen}
        onClose={handleCloseModal}
      >
        <div className="module-Text_List">
          <BulletPoint
            number="1"
            content="Go to mmERCH curated events wearing your hoodie."
          />
          <BulletPoint number="2" content="Tap your NFC chip." />
          <div className="module-Text_BulletPoint animateModule">
            <div className="number">
              <p>3 </p>
            </div>
            <div className="content">
              <p>Get an Experience Token.</p>
              <p>
                No, you can’t send these tokens to other wallets. This hoodie is
                your wallet — your wearable wallet — gaining value through
                culture. (Stay informed on{' '}
                <a
                  href="https://twitter.com/mmerch"
                  target="_blank"
                  rel="noreferrer"
                >
                  X
                </a>{' '}
                )
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <div className="page-Bottom" />
    </div>
  );
}

export default Home;
