const IconXP = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        d="m1.528 24 4.92-8.472L1.576 7.2h3.6l3.336 6.072h.432L12.28 7.2h3.6l-4.872 8.328L15.928 24h-3.6l-3.384-6.216h-.432L5.128 24h-3.6Zm16.501 0V7.2h6.912c1.056 0 1.984.216 2.784.648a4.52 4.52 0 0 1 1.896 1.776c.464.768.696 1.68.696 2.736v.336c0 1.04-.24 1.952-.72 2.736a4.843 4.843 0 0 1-1.92 1.8c-.8.416-1.712.624-2.736.624h-3.744V24H18.03Zm3.168-9.024h3.432c.752 0 1.36-.208 1.824-.624.464-.416.696-.984.696-1.704v-.24c0-.72-.232-1.288-.696-1.704-.464-.416-1.072-.624-1.824-.624h-3.432v4.896Z"
        fill="CurrentColor"
      />
    </svg>
  );
};
export default IconXP;
