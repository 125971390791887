type ModuleProps = {
  children: React.ReactNode;
  className?: string;
  noTopPadding?: boolean;
  noBorder?: boolean;
  isSquare?: boolean;
  aspectRatio?: string;
};

export default function ModuleImage({
  children,
  className,
  noTopPadding,
  noBorder,
  isSquare,
  aspectRatio,
}: ModuleProps) {
  return (
    <div
      className={`module-Image ${className} ${
        noTopPadding ? 'noTopPadding' : ''
      } ${noBorder ? 'noBorder' : ''} ${isSquare ? 'isSquare' : ''}`}
    >
      {children}
    </div>
  );
}
