import './Button.sass';
import { Link, useLocation } from 'react-router-dom';

type ModuleProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  path?: string;
  exitingPath?: string;
  className?: string;
  stretchHor?: boolean;
  stretchVer?: boolean;
  back?: boolean;
  forward?: boolean;
  round?: boolean;
  props?: any;
  hasLoading?: boolean;
};

export default function Module({
  children,
  className,
  stretchHor,
  stretchVer,
  back,
  forward,
  round,
  onClick,
  path,
  exitingPath,
  hasLoading,
  props,
}: ModuleProps) {
  const { search } = useLocation();

  return (
    <>
      {onClick && (
        <div
          onClick={onClick}
          className={`section button ${stretchHor ? 'stretchHor' : ''} ${
            stretchVer ? 'stretchVer' : ''
          } ${back ? 'back' : ''} ${forward ? 'forward' : ''} ${
            round ? 'round' : ''
          } ${className}`}
        >
          {children}
        </div>
      )}

      {/* Keeps searchParams for navigation inside the app */}
      {path && (
        <Link
          className={`section button ${stretchHor ? 'stretchHor' : ''} ${
            stretchVer ? 'stretchVer' : ''
          } ${back ? 'back' : ''} ${forward ? 'forward' : ''} ${
            round ? 'round' : ''
          } ${className}`}
          to={`${path}${search}`}
          target="_self"
        >
          {children}
        </Link>
      )}

      {/* removes searchParams for navigation outside the app */}
      {exitingPath && (
        <Link
          className={`section button ${stretchHor ? 'stretchHor' : ''} ${
            stretchVer ? 'stretchVer' : ''
          } ${back ? 'back' : ''} ${forward ? 'forward' : ''} ${
            round ? 'round' : ''
          } ${className}`}
          to={`${exitingPath}`}
          target="_blank"
        >
          {children}
        </Link>
      )}
    </>
  );
}
