import { useParams } from 'react-router-dom';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import ModuleImage from '../../components/Module/ModuleImage/ModuleImage';
import Button from '../../components/Button/Button';
import IconArrow from '../../components/Icons/IconArrow';
import RunGsap from '../../helpers/RunGsap';
import ButtonContainer from '../../components/ButtonContainer/ButtonContainer';

function TokenSingle(props: { experienceTokens: any[] }) {
  let { id } = useParams();
  let intId = parseInt(id || '0');
  let token = props.experienceTokens[intId];

  return (
    <div className="page page-Token">
      <RunGsap />
      <Module hasEvenCorners noTopPadding>
        <ModuleImage isSquare noTopPadding>
          <img src={token.image} alt="nft" loading="lazy" />
        </ModuleImage>
        <ModuleText>
          <div className="module-Title animateToken">
            <h1>{token.name}</h1>
          </div>
          <div className="module-Text_List">
            <div className="animateToken typeSmall">
              <p>{token.attributes[0].value}</p>
            </div>
            <div className="animateToken">
              <p>{token.description}</p>
            </div>
          </div>
        </ModuleText>
      </Module>
      <ButtonContainer alignLeft>
        <Button className="green" back path="/wallet">
          <div className="icon">
            <IconArrow direction="left" />
          </div>
        </Button>
      </ButtonContainer>
      <div className="page-Bottom" />
    </div>
  );
}

export default TokenSingle;
