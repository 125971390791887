const IconXP = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        d="M15.933 24a1.61 1.61 0 0 0 1.184-.483 1.61 1.61 0 0 0 .483-1.184 1.61 1.61 0 0 0-.483-1.183 1.61 1.61 0 0 0-1.184-.483 1.61 1.61 0 0 0-1.183.483 1.61 1.61 0 0 0-.483 1.183c0 .467.16.861.483 1.184a1.61 1.61 0 0 0 1.183.483Zm-1.2-5.134H17.2c0-.733.083-1.31.25-1.733.167-.422.639-1 1.417-1.733.577-.578 1.033-1.128 1.366-1.65.334-.522.5-1.15.5-1.883 0-1.245-.455-2.2-1.366-2.867-.912-.667-1.99-1-3.234-1-1.266 0-2.294.333-3.083 1-.789.666-1.339 1.466-1.65 2.4l2.2.867c.111-.4.361-.834.75-1.3.389-.467.983-.7 1.783-.7.711 0 1.245.194 1.6.583.356.389.534.816.534 1.283 0 .445-.134.861-.4 1.25-.267.39-.6.75-1 1.084-.978.866-1.578 1.522-1.8 1.966-.223.445-.334 1.256-.334 2.433ZM16 29.334c-1.845 0-3.578-.35-5.2-1.05a13.464 13.464 0 0 1-4.233-2.85c-1.2-1.2-2.15-2.61-2.85-4.233-.7-1.622-1.05-3.356-1.05-5.2 0-1.845.35-3.578 1.05-5.2.7-1.622 1.65-3.034 2.85-4.234 1.2-1.2 2.61-2.15 4.233-2.85 1.622-.7 3.355-1.05 5.2-1.05 1.844 0 3.578.35 5.2 1.05 1.622.7 3.033 1.65 4.233 2.85 1.2 1.2 2.15 2.612 2.85 4.234.7 1.622 1.05 3.355 1.05 5.2 0 1.844-.35 3.578-1.05 5.2a13.464 13.464 0 0 1-2.85 4.233c-1.2 1.2-2.61 2.15-4.233 2.85-1.622.7-3.356 1.05-5.2 1.05Zm0-2.666c2.978 0 5.5-1.034 7.567-3.1 2.066-2.067 3.1-4.59 3.1-7.567 0-2.978-1.034-5.5-3.1-7.567-2.067-2.066-4.59-3.1-7.567-3.1-2.978 0-5.5 1.034-7.567 3.1-2.066 2.067-3.1 4.59-3.1 7.567 0 2.978 1.034 5.5 3.1 7.567 2.067 2.066 4.59 3.1 7.567 3.1Z"
        fill="CuttentColor"
      />
    </svg>
  );
};
export default IconXP;
