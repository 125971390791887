import RouterWrapper from './components/RouterWrapper/RouterWrapper';
import Nav from './components/Nav/Nav';

function App() {
  return (
    <div className="App">
      <Nav />
      <RouterWrapper />
    </div>
  );
}

export default App;
