const IconEvents = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        d="M6.667 29.333a2.568 2.568 0 0 1-1.884-.783A2.568 2.568 0 0 1 4 26.667V8c0-.733.261-1.361.783-1.883a2.568 2.568 0 0 1 1.884-.784H8V2.667h2.667v2.666h10.666V2.667H24v2.666h1.333c.734 0 1.361.261 1.884.784.522.522.783 1.15.783 1.883v18.667c0 .733-.261 1.36-.783 1.883a2.568 2.568 0 0 1-1.884.783H6.667Zm0-2.666h18.666V13.333H6.667v13.334Zm0-16h18.666V8H6.667v2.667Zm9.333 8a1.29 1.29 0 0 1-.95-.384 1.29 1.29 0 0 1-.383-.95c0-.377.127-.694.383-.95A1.29 1.29 0 0 1 16 16c.378 0 .694.128.95.383.256.256.383.573.383.95 0 .378-.127.695-.383.95a1.29 1.29 0 0 1-.95.384Zm-5.333 0a1.29 1.29 0 0 1-.95-.384 1.29 1.29 0 0 1-.384-.95c0-.377.128-.694.384-.95a1.29 1.29 0 0 1 .95-.383c.377 0 .694.128.95.383.255.256.383.573.383.95 0 .378-.128.695-.383.95a1.29 1.29 0 0 1-.95.384Zm10.666 0a1.29 1.29 0 0 1-.95-.384 1.29 1.29 0 0 1-.383-.95c0-.377.128-.694.383-.95a1.29 1.29 0 0 1 .95-.383c.378 0 .695.128.95.383.256.256.384.573.384.95 0 .378-.128.695-.384.95a1.29 1.29 0 0 1-.95.384ZM16 24a1.29 1.29 0 0 1-.95-.383 1.29 1.29 0 0 1-.383-.95c0-.378.127-.695.383-.95a1.29 1.29 0 0 1 .95-.384c.378 0 .694.128.95.384.256.255.383.572.383.95 0 .377-.127.694-.383.95A1.29 1.29 0 0 1 16 24Zm-5.333 0a1.29 1.29 0 0 1-.95-.383 1.29 1.29 0 0 1-.384-.95c0-.378.128-.695.384-.95a1.29 1.29 0 0 1 .95-.384c.377 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.383.95a1.29 1.29 0 0 1-.95.383Zm10.666 0a1.29 1.29 0 0 1-.95-.383 1.29 1.29 0 0 1-.383-.95c0-.378.128-.695.383-.95a1.29 1.29 0 0 1 .95-.384c.378 0 .695.128.95.384.256.255.384.572.384.95 0 .377-.128.694-.384.95a1.29 1.29 0 0 1-.95.383Z"
        fill="CurrentColor"
      />
    </svg>
  );
};
export default IconEvents;
