import './BulletPoint.sass';

type ModuleProps = {
  content?: string;
  contentTwo?: string;
  number?: string;
};

export default function BulletPoint({
  content,
  contentTwo,
  number,
}: ModuleProps) {
  return (
    <div className="module-Text_BulletPoint animateModule">
      {!number && <span className="dot" />}
      {number && (
        <div className="number typeNormal regular">
          <p>{number} </p>
        </div>
      )}
      <div className="content typeNormal regular">
        <p>{content}</p>
        {contentTwo ? <p>{contentTwo}</p> : null}
      </div>
    </div>
  );
}
