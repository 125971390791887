import React, { useState } from 'react';
import useSwipe from '../../../helpers/useSwipe';
import './ModuleSlider.sass';
import Loader from '../../Loader/Loader';

type ModuleProps = {
  images: string[];
  caption?: string;
  url?: string;
};

export default function Module({ images, caption, url }: ModuleProps) {
  // Slider
  const [loading, setLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === images.length - 1;

  const nextSlide = () => {
    if (!isLastSlide) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (!isFirstSlide) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  function handleClick() {
    if (isLastSlide) {
      return prevSlide();
    } else {
      return nextSlide();
    }
  }

  // Swiper
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      handleSwipeLeft();
    },
    onSwipedRight: () => {
      handleSwipeRight();
    },
  });

  function handleSwipeLeft() {
    if (isLastSlide) {
      return prevSlide();
    } else {
      return nextSlide();
    }
  }

  function handleSwipeRight() {
    if (isFirstSlide) {
      return nextSlide();
    } else {
      return prevSlide();
    }
  }

  return (
    <div className="module-Slider">
      <div
        {...swipeHandlers}
        onClick={handleClick}
        data-value={currentSlide}
        className={`module-Slider_Image`}
      >
        <div style={{ display: loading ? 'block' : 'none' }}>
          <Loader />
        </div>
        <img
          src={images[currentSlide]}
          alt=""
          onLoad={() => setLoading(false)}
          style={{ display: loading ? 'none' : 'block' }}
        />
      </div>
      {/* <div className="module-Slider_Caption typeSmall">
        {caption}
        <a href={url} target="_blank" rel="noreferrer">
          <img src={require('../../../assets/icons/opensea.png')} alt="" />
        </a>
      </div> */}
      <div className="nav-Dots">
        <li
          onClick={prevSlide}
          className={`nav-Dot ${isFirstSlide ? 'active' : ''}`}
        ></li>
        <li
          onClick={nextSlide}
          className={`nav-Dot ${isLastSlide ? 'active' : ''}`}
        />
      </div>
    </div>
  );
}
