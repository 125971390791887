import './Loader.sass';
export default function Module() {
  return (
    <div className="loader">
      <div className="dots">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  );
}
