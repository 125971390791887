import './Subtitle.sass';
import IconHelp from '../Icons/IconHelp';

type ModuleProps = {
  text?: string;
  help?: boolean;
  id?: string;
  className?: string;
  onClick?: () => void;
};

export default function Subtitle({
  text,
  help,
  id,
  className,
  onClick,
}: ModuleProps) {
  return (
    <div id={id} className={`subtitle section ${className}`}>
      <span className="subtitle-Text">{text}</span>
      {help && (
        <div className="icon icon-Help" onClick={onClick}>
          <IconHelp />
        </div>
      )}
    </div>
  );
}
