import { Link, useLocation } from 'react-router-dom';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import RunGsap from '../../helpers/RunGsap';
import testTokenImage from '../../assets/img/wwturrell-square.png';
import ButtonContainer from '../../components/ButtonContainer/ButtonContainer';
import Button from '../../components/Button/Button';
import IconArrow from '../../components/Icons/IconArrow';

function Wallet(props: { experienceTokens: any[] }) {
  let experienceTokens = props.experienceTokens;

  const { search } = useLocation();

  return (
    <div className="page page-Wallet">
      <RunGsap />
      <Module>
        <ModuleText>
          <div className="module-Title">
            <h1>Your XP</h1>
          </div>
        </ModuleText>
        <ModuleText>
          <div className="module-Tokens ">
            {/* Test Token */}
            {/* <div className="module-Tokens_Item active" key={9}>
              <div className="module-Tokens_Item_New">
                <p className="animationPulse">New</p>
              </div>
              <Link to={`/tokenSingle/momatoken`}>
                <img src={testTokenImage} alt="" />
              </Link>
            </div> */}
            {experienceTokens
              .slice()
              .reverse()
              .map((item: any, index) => (
                <div
                  className="module-Tokens_Item active"
                  key={experienceTokens.length - 1 - index}
                >
                  <Link
                    to={`/tokenSingle/${
                      experienceTokens.length - 1 - index
                    }${search}`}
                  >
                    <img src={item.image} alt="" />
                  </Link>
                </div>
              ))}
            {/* Add up to 5 placeholder tokens */}
            {Array.from({ length: 5 - experienceTokens.length }, (_, index) => (
              <div className="module-Tokens_Item" key={index}>
                <span>
                  Experience
                  <br />
                  Token
                </span>
              </div>
            ))}
          </div>
        </ModuleText>
        <ButtonContainer alignRight>
          <Button forward className="green" path="/">
            Collect more
            <div className="icon">
              <IconArrow direction="forward" />
            </div>
          </Button>
        </ButtonContainer>
      </Module>
      <div className="page-Bottom" />
    </div>
  );
}

export default Wallet;
