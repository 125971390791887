import { useState } from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import ModuleImage from '../../components/Module/ModuleImage/ModuleImage';
import Button from '../../components/Button/Button';
import IconArrow from '../../components/Icons/IconArrow';
import IconLocate from '../../components/Icons/IconLocate';
import QRCode from '../../components/Icons/QRCode';
import Modal from '../../components/Modal/Modal';
import RunGsap from '../../helpers/RunGsap';
import getCookie from '../../helpers/getCookie';
import ButtonContainer from '../../components/ButtonContainer/ButtonContainer';
import Loader from '../../components/Loader/Loader';
import { FETCH_STATUS } from '../../helpers/fetchStatus';

interface ErrorState {
  errorId: number;
  errorTitle: string;
  errorCopy: string;
}

function EventSingle(props: { nftData: any; eventData: any[] }) {
  const [jwt, setJwt] = useState<string | null>(null);
  const [haveToken, setHaveToken] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams('');

  useEffect(() => {
    setJwt(getCookie('__Secure-auth') || null);
  }, []);

  useEffect(() => {
    if (!!jwt) {
      setHaveToken(true);
    } else {
      setHaveToken(false);
    }
  }, [jwt]);

  const [ModalOpenQR, setModalOpenQR] = useState(false);
  const [ModalOpenCheckin, setModalOpenCheckin] = useState(false);
  // const [ModalOpenGenerateQR, setModalOpenGenerateQR] = useState(false);
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [hasTicket, setHasTicket] = useState(false);
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);
  const [hasError, setHasError] = useState<ErrorState>({
    errorId: 0,
    errorTitle: '',
    errorCopy: '',
  });
  const [responseData, setResponseData] = useState<any>({});

  let nftData = props.nftData;
  const hoodieNumber = nftData.number;

  // Filter eventData by slug
  const { slug } = useParams();
  const eventData = Array.isArray(props.eventData) ? props.eventData : [];
  const event = eventData.find((event) => event.path === slug);

  // To test API calls
  const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds

  // To show ticket countdown timer
  const formatTime = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, '0')}:${m
      .toString()
      .padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };


  /**
   * @Dries - there is a better way to do this
   * look up how to do this using "URL Encoded strings"
   */
  // RSVP EMAIL LINK
  const emailSubject = 'I’m%20going%20to%20be%20in%20Marfa!';
  const emailBody = `Hi,%20mmERCH!\n \nIf%20there%20are%20spots%20left,%20I’d%20love%20to%20be%20added%20to%20the%20list%20for%20the%20private%20tour%20of%20the%20Chinati%20Foundation%20on%20November%2016,%202024!
${hoodieNumber ? `%0A%0AHoodie%20token%20${hoodieNumber}` : ''}`;
  const emailLink = `mailto:info@mmerch.xyz?subject=${emailSubject}&body=${emailBody}`;

  // ERROR EMAIL :LINK
  const helpEmailLink = (number: number) => {
    const helpEmailSubject = 'Some%20check%20in%20help%20needed!';
    const helpEmailBody = `Hi,%20mmERCH!%0A%0AI%20need%20some%20help%20checking%20into%20${
      event?.name
    }.%0A%0AEvent-ID:%20${event ? event.eventId : 'UNDEFINED'}%0AHoodie-ID%20${
      hoodieNumber ? hoodieNumber : 'UNDEFINED'
    }%0AIssue-ID:%20${number}`;
    return `mailto:info@mmerch.xyz?subject=${helpEmailSubject}&body=${helpEmailBody}`;
  };

  const getPosition = () => {
    setStatus(FETCH_STATUS.LOADING);
    // Get geolocation
    if (!navigator.geolocation) {
      setStatus(FETCH_STATUS.ERROR);
      setHasError({
        errorId: 4,
        errorTitle: 'Oh Oh...',
        errorCopy:
          'It seems your browser does not support geolocation. (1) Try a different browser or (2) ',
      });
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        setPosition(position);

        // Data package
        const data = {
          eventId: event.eventId.toString(),
          lat: position.coords.latitude.toString(),
          long: position.coords.longitude.toString(),
        };

        let uuid = searchParams.get('uuid') || '';

        // Post data package
        try {
          const response = await axios.post(
            `https://router.mmerch.xyz/attendance/${uuid}`,
            data,
            {
              headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                // FIXME: this is the token for the test user, hardcoded for now - Token Expires 12/01/24
                Authorization: `Bearer ${jwt}`,
              },
            }
          );

          console.log('GET POSITION API RESPONSE', response.data);

          setStatus(FETCH_STATUS.SUCCESS);
          setResponseData(response.data);
        } catch (error) {
          // Catch DATABASE errors
          console.error('Error sending check-in data:', error);
          setStatus(FETCH_STATUS.ERROR);
          setHasError({
            errorId: 5,
            errorTitle: 'Well, that didn’t work.',
            errorCopy:
              'There seems to be a server error. (1) Please check your connection (2) try again or (3) ',
          });
        }
      },
      // Catch GEOLOCATION errors
      (error) => {
        setStatus(FETCH_STATUS.ERROR);
        if (error.code === 0) {
          setHasError({
            errorId: 0,
            errorTitle: 'Something’s amiss',
            errorCopy:
              'It seems your location is unavailable. (1) Please try again, (2) check your settings (3) try a different browser or (4) ',
          });
        } else if (error.code === error.PERMISSION_DENIED || error.code === 1) {
          setHasError({
            errorId: 1,
            errorTitle: 'Uh…',
            errorCopy:
              'It seems you’ve denied us access. Perhaps it was an accident. So: (1) please try again (2) check settings (3) try a different browser or (4) ',
          });
        } else if (
          error.code === error.POSITION_UNAVAILABLE ||
          error.code === 2
        ) {
          setHasError({
            errorId: 2,
            errorTitle: 'Hmmm...curious....we can’t find you',
            errorCopy:
              'It seems your location is unavailable. (1) please try again, (2) check settings, (3) try a different browser or (4) ',
          });
        } else if (error.code === error.TIMEOUT || error.code === 3) {
          setHasError({
            errorId: 3,
            errorTitle: 'Ooops',
            errorCopy: 'Hmm...that took too long. (1) please try again or (2) ',
          });
        }
        console.log('error', error);
      },
      {
        timeout: 1000, // 10 seconds timeout
        maximumAge: 1000, // accept only a position not older than 60 seconds
        enableHighAccuracy: true, // high accuracy
      }
    );
  };

  const handleCloseModalQR = () => {
    setModalOpenQR(false);
  };

  const handleOpenModalGenerateQR = () => {
    if (!hasTicket) {
      setTimeout(() => {
        setModalOpenQR(true);
        setHasTicket(true);
      }, 1500);
    } else {
      setModalOpenQR(true);
    }
  };

  const handleOpenModalCheckin = () => {
    setModalOpenCheckin(true);
    getPosition();
  };

  const handleCloseModalCheckin = () => {
    setModalOpenCheckin(false);
  };

  return (
    <div className="page page-Token">
      <RunGsap />
      <Module hasEvenCorners noTopPadding>
        <ModuleImage noTopPadding aspectRatio="13:16">
          {event && <img src={event.image} alt="nft" loading="lazy" />}
        </ModuleImage>
        <ModuleText>
          <div className="module-Title animateToken">
            {event && <h1>{event.name}</h1>}
          </div>
          {event && (event.date || event.location) && (
            <div className="module-Text_List">
              <p className="typeSmall normal animateToken">
                {event.date}
                {event.location && (
                  <>
                    <br />
                    {event.location}
                  </>
                )}
              </p>
              {event.description && (
                <p className="typeNormal normal animateToken">
                  {event.description}
                </p>
              )}
              {event.link.linkShow && (
                <div className="module-Text_Link typeSmall normal animateToken">
                  <a href={event.link.linkUrl}>
                    {event.link.linkText}
                    <div className="icon">
                      <IconArrow direction="link" />
                    </div>
                  </a>
                </div>
              )}
            </div>
          )}
        </ModuleText>
        <ButtonContainer stretch vertical>
          {/* RSVP  */}
          {event && event.buttonVariation === 'RSVP' && (
            <Button className="green" stretchHor exitingPath={emailLink}>
              <p>{event.buttonText}</p>
            </Button>
          )}
          {/* GEOLOCATION  */}
          {/* { haveToken && (<p>{jwt}</p>) } */}
          {event && event.buttonVariation === 'geolocation' && haveToken && (
            <>
              <Button
                className="green"
                stretchHor
                // @Dries - should we not permit check-in attempts
                // if there is no valid security token for this user?
                // because the security token is required to check-in
                //disabled={!haveToken}
                onClick={handleOpenModalCheckin}
              >
                <div className="icon" />
                <p>CHECK IN</p>
                <div className="icon">
                  <IconLocate />
                </div>
              </Button>
              <span className="typeSmall normal animateToken">
                When you check in, you’ll be asked to share your location.
              </span>
            </>
          )}
          {/* GENERATE QR  */}
          {event && event.buttonVariation === 'generateQr' && (
            <Button
              className="green"
              stretchHor
              onClick={handleOpenModalGenerateQR}
            >
              <p>{event.buttonText}</p>
            </Button>
          )}
        </ButtonContainer>
      </Module>
      <ButtonContainer alignLeft>
        <Button className="green" back path="/">
          <div className="icon">
            <IconArrow direction="left" />
          </div>
        </Button>
      </ButtonContainer>
      <Modal
        title="Your ticket"
        isOpen={ModalOpenQR}
        onClose={handleCloseModalQR}
        btnTitle="close"
        hasCloseBtn
      >
        <div className="QRCode">
          <QRCode />
        </div>
        <div className="QRCode_Text">
          <p>
            Your ticket is valid for:
            <br />
            {formatTime(timeLeft)}
          </p>
        </div>
      </Modal>
      {/* CHECK IN MODAL */}
      <Modal
        isOpen={ModalOpenCheckin}
        onClose={handleCloseModalCheckin}
        hasCloseBtn
      >
        {status === FETCH_STATUS.LOADING && <Loader />}
        {status === FETCH_STATUS.SUCCESS && (
          <>
            <h1>Yes!</h1>
            <p>
              Your XP is being airdropped now! View it in your Wearable Wallet.
            </p>
          </>
        )}
        {status === FETCH_STATUS.ERROR && (
          <>
            <h1>{hasError.errorTitle}</h1>
            <p>
              {hasError.errorCopy}{' '}
              <a
                href={helpEmailLink(hasError.errorId)}
                target="_blank"
                rel="noreferrer"
              >
                send us an email
              </a>
              .
            </p>
          </>
        )}
      </Modal>
      <div className="page-Bottom" />
    </div>
  );
}

export default EventSingle;
