const IconLocate = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 32">
      <path
        d="M21.96 5.573c0-.77-.374-1.144-1.012-1.144-.352 0-.814.154-1.364.44L16.9 6.32l-2.684-1.452c-.55-.286-.99-.44-1.364-.44-.682 0-1.012.374-1.012 1.144v5.104c0 1.012.704 1.54 1.188 1.98l1.144 1.056c.814.748 1.232 1.584 1.232 2.552v1.1c0 .77-.22 1.166-.682 1.166-.176 0-1.232-.286-1.606-.286-.792 0-1.276.484-1.276 1.408 0 .418.176.792.506 1.122l3.014 2.97c.682.682.99.836 1.54.836.528 0 .836-.154 1.54-.836l2.772-2.728c.352-.352.572-.616.638-.792.066-.198.11-.396.11-.572 0-.924-.462-1.408-1.276-1.408-.484 0-1.254.286-1.606.286-.462 0-.682-.396-.682-1.166v-1.1c0-.946.418-1.804 1.232-2.552l1.144-1.056c1.034-.968 1.188-1.232 1.188-1.98V5.573Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.112 21.553C8.04 22.206 6 23.354 6 24.662c0 2.032 4.925 3.68 11 3.68s11-1.648 11-3.68c0-1.338-2.134-2.509-5.326-3.153-.132.14-.308.304-.526.491l-.92.78c1.171.173 2.212.415 3.074.703.908.303 1.534.632 1.903.923.147.116.224.202.264.256-.04.054-.117.14-.264.256-.369.291-.995.62-1.903.923-1.8.602-4.381 1-7.302 1-2.92 0-5.503-.398-7.302-1-.908-.303-1.534-.632-1.903-.923a1.444 1.444 0 0 1-.264-.256c.04-.054.117-.14.264-.256.369-.291.995-.62 1.903-.923.811-.272 1.782-.502 2.87-.672l-1.256-1.065a2.38 2.38 0 0 1-.2-.193Zm15.397 3.042s0 .003-.003.007a.03.03 0 0 1 .003-.007Zm-19.018 0 .003.008a.025.025 0 0 1-.003-.008Zm0 .134s0-.003.003-.008a.028.028 0 0 1-.003.008Zm19.015-.008a.024.024 0 0 1 .003.008.03.03 0 0 1-.003-.008Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default IconLocate;
