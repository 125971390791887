import styles from './EventCard.module.sass';
import Button from '../Button/Button';
import IconArrow from '../Icons/IconArrow';
import ButtonContainer from '../ButtonContainer/ButtonContainer';

type ModuleProps = {
  hasButton?: boolean;
  background?: string;
  path?: string;
};

export default function Event({ hasButton, background, path }: ModuleProps) {
  return (
    <div
      className={`${styles.eventCard} section`}
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {hasButton && (
        <ButtonContainer className={styles.buttonContainer} alignRight>
          <Button className="green" forward path={'/eventSingle/' + path}>
            <div className="icon">
              <IconArrow direction="forward" />
            </div>
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
}
