const IconArrow = ({ direction }: { direction: any }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${direction ? direction : ''}`}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.851 14.222h18.704a1.818 1.818 0 1 1 0 3.636H8.808l7.093 7.093a1.818 1.818 0 0 1-2.571 2.572L3.159 17.352a1.818 1.818 0 0 1-.04-2.53c.033-.04.068-.079.106-.116l10.17-10.171a1.818 1.818 0 0 1 2.572 2.571l-7.116 7.116Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IconArrow;
