import './ButtonContainer.sass';
import { Link } from 'react-router-dom';

type ModuleProps = {
  children?: React.ReactNode;
  alignLeft?: boolean;
  alignRight?: boolean;
  alignCenter?: boolean;
  floatRight?: boolean;
  horizontal?: boolean;
  vertical?: boolean;
  stretch?: boolean;
  className?: string;
};

export default function Module({
  children,
  alignLeft,
  alignRight,
  alignCenter,
  floatRight,
  horizontal,
  vertical,
  stretch,
  className,
}: ModuleProps) {
  return (
    <div
      className={`buttonContainer ${className && className} ${
        alignLeft ? 'left' : ''
      } ${alignCenter ? 'center' : ''} ${alignRight ? 'right' : ''} ${
        stretch ? 'stretch' : ''
      } ${vertical ? 'vertical' : ''} ${horizontal ? 'horizontal' : ''} ${
        floatRight ? 'floatRight' : ''
      }`}
    >
      {children}
    </div>
  );
}
