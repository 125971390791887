import './Nav.sass';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IconEvents from '../Icons/IconEvents';
import IconXP from '../Icons/IconXP';
import IconCloset from '../Icons/IconCloset';
import IconSettings from '../Icons/IconSettings';
// import IconEventsActive from '../Icons/IconEventsActive';
// import IconXPActive from '../Icons/IconXPActive';
// import IconClosetActive from '../Icons/IconClosetActive';
// import IconSettingsActive from '../Icons/IconSettingsActive';

export default function Nav() {
  const [navPosition, setNavPosition] = useState(0);
  const [indicatorActive, setindicatorActive] = useState(true);
  const location = useLocation();
  const { search } = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const parts = path.split('/');
    switch (parts[1]) {
      case '':
        setNavPosition(0);
        setindicatorActive(true);
        break;
      case 'wallet':
        setNavPosition(1);
        setindicatorActive(true);
        break;
      case 'traits':
        setNavPosition(2);
        setindicatorActive(true);
        break;
      case 'settings':
        setNavPosition(3);
        setindicatorActive(true);
        break;
      case 'eventSingle':
        setindicatorActive(false);
        break;
      case 'tokenSingle':
        setindicatorActive(false);
        break;
      default:
        setNavPosition(0);
    }
  }, [location]);

  return (
    <nav className="nav">
      <div className="nav-Expanded"></div>
      <div className="nav-Collapsed">
        <div
          className="indicator"
          data-state={navPosition}
          data-active={indicatorActive}
        />
        <NavLink to={'/' + search}>
          <div className="icon nav-Icon">
            <IconEvents />
          </div>
        </NavLink>
        <NavLink to={'/wallet' + search}>
          <div className="icon nav-Icon">
            <IconXP />
          </div>
        </NavLink>
        <NavLink to={'/traits' + search}>
          <div className="icon nav-Icon">
            <IconCloset />
          </div>
        </NavLink>
        {/* <NavLink to={'/settings' + search}>
          <div className="icon nav-Icon">
            <IconSettings />
          </div>
        </NavLink> */}
      </div>
    </nav>
  );
}
