import RunGsap from '../../helpers/RunGsap';
import './Traits.sass';
import DataRow from '../../components/Module/ModuleText/Trait/Trait';
import ModuleSlider from '../../components/Module/ModuleSlider/ModuleSlider';

function Traits(props: { nftData: any }) {
  //@ts-ignore
  function openseaURL(nftData): any {
    const isAP = nftData.sku.indexOf('AP') > -1;
    const parsedNum = parseInt(nftData.number, 10);
    let finalNumber = parsedNum;
    // the collection URL for the PostClaim collection
    let openseaUrl =
      'https://opensea.io/assets/ethereum/0x369662533e0ca58bdb135e302d1570793009a7d2/';
    if (isAP) {
      // Artist Prototypes are 0 indexed, but OpenSea needs them 1 Indexed
      // SO: Token #000 in the collection is OpenSea Token id 1
      finalNumber = parsedNum + 1;
      // the collection URL for the Artist Prototype collection
      openseaUrl =
        'https://opensea.io/assets/ethereum/0x3db83db7d88868bdd697e53b7f3de28d380517dd/';
    }
    return `${openseaUrl}${finalNumber.toString()}`;
  }

  let nftData = props.nftData;

  return (
    <div className="page">
      <RunGsap />
      <div className={`traitsModule section`}>
        <div className="traitsModule-Content">
          <ModuleSlider images={[nftData?.renderImage, nftData?.nftImage]} />
          <div className="traitsModule-TitleContainer animateToken">
            <div className="module-Title center">
              <h1>{nftData.traits.Name}</h1>
            </div>
            <div className="traitsModule-DesignTraits animateToken">
              <p>Design Traits</p>
            </div>
          </div>
          <div className="traitsModule-Traits animateToken">
            {Object.entries(nftData.traits) &&
              Object.entries(nftData.traits).map((item: any, index) => (
                <DataRow
                  key={index}
                  horizontal
                  title={item[0]}
                  value={item[1]}
                />
              ))}
          </div>
        </div>
      </div>

      <div className="page-Bottom" />
    </div>
  );
}

export default Traits;
