import './Trait.sass';

export default function Trait(props: {
  value?: string;
  title?: string;
  horizontal?: boolean;
}) {
  return (
    <div
      className={`module-Text_Trait typeNormal regular ${
        props.horizontal ? 'horizontal' : ''
      }`}
    >
      <span>
        <strong className="title typeNormal bold">
          {props.title}
          {props.horizontal && <span>:</span>}
        </strong>
      </span>
      <span className="line" />
      <span>{props.value}</span>
    </div>
  );
}
