const IconPlus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 5a2 2 0 0 0-2 2v7H7a2 2 0 1 0 0 4h7v7a2 2 0 1 0 4 0v-7h7a2 2 0 1 0 0-4h-7V7a2 2 0 0 0-2-2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IconPlus;
